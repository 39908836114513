import styled from "styled-components";

export const CheckboxWrapper = styled.div`
  box-sizing: inherit;
  display: flex;

  & *:before,
  & *:after {
    box-sizing: inherit;
  }
`;

export const CheckboxLabel = styled.label`
  padding-left: 10px;
  font-size: 14px;
  font-weight: 550;
`;

export const CheckboxInput = styled.input`
  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    --active: #19bd44;
    --active-inner: #fff;
    --focus: 2px #36ff6c52;
    --border: #dfdfdf;
    --border-hover: #19df4e;
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    &:after {
      content: "";
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
        opacity var(--d-o, 0.2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: 0.3s;
      --d-t: 0.6s;
      --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: 0.9;
    }
    &:disabled:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }
    &:disabled + ${CheckboxLabel} {
      cursor: not-allowed;
    }
    &:hover:not(:checked):not(:disabled) {
      --bc: var(--border-hover);
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 21px;
    }
    &:not(.switch):after {
      opacity: var(--o, 0);
    }
    &:not(.switch):checked {
      --o: 1;
    }
    & + ${CheckboxLabel} {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      margin-left: 4px;
    }
    &:not(.switch) {
      border-radius: 7px;
    }
    &:not(.switch):after {
      width: 5px;
      height: 9px;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 7px;
      top: 4px;
      transform: rotate(var(--r, 20deg));
    }
    &:not(.switch):checked {
      --r: 43deg;
    }
  }
`;
