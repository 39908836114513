import styled from "styled-components";

export const Input = styled.input`
  margin-top: 5px;
  width: 100%;
  padding: 12px 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  letter-spacing: 0.4px;
  &:focus {
    outline: none !important;
    border: 1px solid #dfdfdf;
  }
`;

export const InputBox = styled.div`
  padding: 15px 0px;
`;
