import React from "react";
import GlobalStyles from "../components/styles/global";
import { Input, InputBox } from "../components/styles/input";
import { Link, Pg1, Pg2, Pg3, T1 } from "../components/styles/typography";
import {
  BackgroundContainer,
  Flex,
  LoginContainer,
  LoginForm,
} from "../components/styles/container";
import { PrimaryButton } from "../components/styles/button";
import {
  CheckboxInput,
  CheckboxLabel,
  CheckboxWrapper,
} from "../components/styles/checkbox";

export const Login = () => {
  return (
    <>
      <GlobalStyles></GlobalStyles>
      <Flex>
        <LoginContainer>
          <LoginForm>
            <T1>Welcome back.</T1>
            <Pg2>Please enter your details.</Pg2>
            <InputBox>
              <Pg1>Email</Pg1>
              <Input type="text" placeholder="Enter your email" />
            </InputBox>
            <Pg1>Password</Pg1>
            <Input type="password" placeholder="Password" />
            <Flex
              style={{ justifyContent: "space-between", marginTop: "15px" }}
            >
              <CheckboxWrapper>
                <CheckboxInput type="checkbox" id="checkbox" />
                <CheckboxLabel
                  for="checkbox"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Remember me
                </CheckboxLabel>
              </CheckboxWrapper>
              <Link style={{ display: "flex", alignItems: "center" }}>
                Forgot Password
              </Link>
            </Flex>
            <PrimaryButton type="submit">Sign In</PrimaryButton>
            <Pg3>
              Don't have an account yet? <Link>Sign Up</Link>{" "}
            </Pg3>
          </LoginForm>
        </LoginContainer>
        <BackgroundContainer></BackgroundContainer>
      </Flex>
    </>
  );
};
