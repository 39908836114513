import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
`;

export const LoginContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const LoginForm = styled.div`
  padding: 40px 0px;
  width: 48%;
  margin: 100px auto;
  @media (max-width: 1450px) {
    width: 50%;
  }
  @media (max-width: 1380px) {
    width: 55%;
  }
  @media (max-width: 1250px) {
    width: 60%;
  }
  @media (max-width: 1150px) {
    width: 65%;
  }
  @media (max-width: 1058px) {
    width: 70%;
  }
  @media (max-width: 990px) {
    width: 75%;
  }
  @media (max-width: 945px) {
    margin: 10% auto;
    width: 60%;
  }
  @media (max-width: 780px) {
    width: 70%;
  }
  @media (max-width: 520px) {
    width: 80%;
  }
`;

export const BackgroundContainer = styled.div`
  height: 100vh;
  width: 100%;
  background: url("https://images.pexels.com/photos/163143/sackcloth-sackcloth-textured-laptop-ipad-163143.jpeg");
  background-size: cover;
  background-position: center;
  @media (max-width: 945px) {
    display: none;
    width: 0%;
  }
`;
